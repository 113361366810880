@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
:root {
	--purple: #764899;
	--pink: #d93994;
	--orange: #f2a524;
	--grey: #c6c6c6;
	--dark: #2f3d47;
}

* {
	margin: 0;
	padding: 0;
}

body {
	font-family: "Helvetica Neue", "Poppins", sans-serif;
	color: #2f3d47;
}

a {
	text-decoration: none;
	color: inherit;
}

